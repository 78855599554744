import React from 'react';
import Layout from "../components/Layout/Layout";
import SimpleTable from "../components/Basics/SimpleTable";

export const MainPage = () => {
    return (
        <Layout>
            <SimpleTable/>
        </Layout>
    )
}

export default MainPage