import {IFoodCategoryState} from '../../utils/models/IFoodCategory'
import {
    SET_FOOD_CATEGORY_DATA
} from '../types'

export const initialState: IFoodCategoryState = {
    items: []
}

export const foodCategoryReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_FOOD_CATEGORY_DATA: {
            return {...state, items: payload}
        }
        default:
            return state
    }
}