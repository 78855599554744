import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {DateTimePicker} from '@material-ui/pickers'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'

class RuLocalizedUtils extends DateFnsUtils {

    getCalendarHeaderText(date) {
        return this.format(date, 'LLLL')
    }

    getDatePickerHeaderText(date) {
        return this.format(date, 'dd MMMM')
    }

    dateFormat: 'dd'
}

const useStyles = makeStyles(() => ({
    field: {
        position: 'relative'
    },
    errorBox: {
        color: '#ff2668',
        fontSize: 11,
        position: 'absolute',
        bottom: -16,
        left: 0
    }
}))

export const DatePicker = ({name, value, label, disabled, onChange, disablePast = false}) => {
    const classes = useStyles()
    const [selectedDate, setDate] = useState(value || null)

    const handleDateChange = (e) => {
        const tzOffset = e.getTimezoneOffset() * 60000
        setDate((new Date(e - tzOffset)).toISOString().slice(0, -1))
    }

    useEffect(() => {
        selectedDate &&
        onChange(selectedDate, name)(null)
    }, [selectedDate])

    return (
        <MuiPickersUtilsProvider
            utils={RuLocalizedUtils}
            locale={ruLocale}
        >
            <Box className={classes.field} maxWidth={500} mb={2}>
            <DateTimePicker
                fullWidth
                value={selectedDate}
                onChange={handleDateChange}
                ampm={false}
                format={'d MMM yyyy в HH:mm'}
                label={label}
                disabled={disabled}
                disablePast={disablePast}
            />
            </Box>
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker