import {bool, dateTime, email, html, rubPrice, url, image} from '../helpers/gridColumnTypes'
import {IEntity} from '../utils/models/IEntity'
import * as Yup from 'yup'

export const entities: IEntity[] = [
    {
        code: 'user',
        title: 'Пользователи',
        title2: 'пользователя',
        icon: 'group',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {field: 'fullname', headerName: 'Предпочитаемое имя', flex: 1, fieldType: 'text'},
            {
                field: 'username',
                headerName: 'Имя пользователя',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'password', headerName: 'Пароль', flex: 1, fieldType: 'password', hide: true},
            {
                field: 'email',
                headerName: 'Электронная почта',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле').email('Неверный формат адреса электронной почты'), ...email
            },
            {
                field: 'roles',
                headerName: 'Роли',
                flex: 1,
                fieldType: 'checkboxGroup',
                variant: 'row',
                validation: Yup.array().min(1, 'Должна быть указана хотя бы одна роль'),
                hide: true,
                values: [
                    {value: 'admin', title: 'Администратор', disabled: false},
                    {value: 'manager', title: 'Менеджер', disabled: false},
                    {value: 'photo', title: 'Фотограф', disabled: false}
                ]
            }
        ]
    },
    {
        code: 'event',
        title: 'События',
        title2: 'события',
        icon: 'receipt',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'title',
                headerName: 'Заголовок',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'subtitle', headerName: 'Подзаголовок', flex: 1, fieldType: 'text', hide: true},
            {
                field: 'imageId',
                headerName: 'Изображение',
                flex: 1,
                fieldType: 'file',
                hide: true,
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png']
            },
            {field: 'featured', headerName: 'Значимое событие', flex: 1, fieldType: 'boolean', ...bool},
            {field: 'price', headerName: 'Стоимость от', flex: 1, fieldType: 'text', hide: true},
            {field: 'strPrice', headerName: 'Плейсхолдер цены', flex: 1, fieldType: 'text', hide: true},
            {field: 'buttonName', headerName: 'Текст кнопки', flex: 1, fieldType: 'text', hide: true},
            {field: 'ticketsUrl', headerName: 'Ссылка на билет', flex: 1, fieldType: 'text', hide: true},
            {
                field: 'shortDescription',
                headerName: 'Короткое описание',
                flex: 1,
                fieldType: 'htmlEditor',
                hide: true, ...html
            },
            {
                field: 'description',
                headerName: 'Полное описание',
                flex: 1,
                fieldType: 'htmlEditor',
                hide: true, ...html
            },
            {field: 'startTime', headerName: 'Начало', flex: 1, fieldType: 'date', ...dateTime},
            {field: 'endTime', headerName: 'Конец', flex: 1, fieldType: 'date', ...dateTime, hide: true},
            {
                field: 'comment',
                headerName: 'Комментарий',
                flex: 1,
                fieldType: 'textarea',
            },
        ],
        sortModel: {field: 'startTime', sort: 'desc'},
        copyable: true
    },
    {
        code: 'event:archived',
        title: 'Архив событий',
        title2: 'события',
        icon: 'history_toggle_off',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'title',
                headerName: 'Заголовок',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'subtitle', headerName: 'Подзаголовок', flex: 1, fieldType: 'text', hide: true},
            {
                field: 'imageId',
                headerName: 'Изображение',
                flex: 1,
                fieldType: 'file',
                hide: true,
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png']
            },
            {field: 'featured', headerName: 'Значимое событие', flex: 1, fieldType: 'boolean', ...bool},
            {field: 'price', headerName: 'Стоимость от', flex: 1, fieldType: 'text', hide: true},
            {field: 'strPrice', headerName: 'Плейсхолдер цены', flex: 1, fieldType: 'text', hide: true},
            {field: 'buttonName', headerName: 'Текст кнопки', flex: 1, fieldType: 'text', hide: true},
            {field: 'ticketsUrl', headerName: 'Ссылка на билет', flex: 1, fieldType: 'text', hide: true},
            {
                field: 'shortDescription',
                headerName: 'Короткое описание',
                flex: 1,
                fieldType: 'htmlEditor',
                hide: true, ...html
            },
            {
                field: 'description',
                headerName: 'Полное описание',
                flex: 1,
                fieldType: 'htmlEditor',
                hide: true, ...html
            },
            {field: 'startTime', headerName: 'Начало', flex: 1, fieldType: 'date', ...dateTime},
            {field: 'endTime', headerName: 'Конец', flex: 1, fieldType: 'date', ...dateTime, hide: true},
            {
                field: 'comment',
                headerName: 'Комментарий',
                flex: 1,
                fieldType: 'textarea',
            },
        ],
        sortModel: {field: 'startTime', sort: 'desc'},
        nonCreatable: true,
        nonEditable: true,
        copyable: true
    },
    {
        code: 'news',
        title: 'Новости',
        title2: 'новости',
        icon: 'notifications_active',
        fieldsMap: [
            {field: 'id', headerName: 'ID', fieldType: 'text', editability: 'auto', hide: true},
            {field: 'time', headerName: 'Дата создания', flex: 1, fieldType: 'date', editability: 'auto', ...dateTime},
            {
                field: 'title',
                headerName: 'Заголовок',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {
                field: 'body',
                headerName: 'Тело новости',
                flex: 1,
                fieldType: 'htmlEditor',
                hide: true, ...html,
                validation: Yup.string().required('Это обязательное поле')
            },
        ],
        sortModel: {field: 'time', sort: 'desc'}
    },
    {
        code: 'guestbook',
        title: 'Гостевая книга',
        title2: 'записи гостевой книги',
        icon: 'menu_book',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {field: 'time', headerName: 'Дата создания', flex: 1, fieldType: 'date', editability: 'auto', ...dateTime},
            {
                field: 'name',
                headerName: 'Имя',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'city', headerName: 'Город', fieldType: 'text', hide: true},
            {
                field: 'email',
                headerName: 'Электронная почта',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле').email('Неверный формат адреса электронной почты'), ...email
            },
            {field: 'url', headerName: 'URL', flex: 1, fieldType: 'text', ...url},
            {field: 'message', headerName: 'Сообщение', flex: 1, fieldType: 'textarea'},
        ],
        sortModel: {field: 'time', sort: 'desc'}
    },
    {
        code: 'main_menu',
        title: 'Пункты главного меню',
        title2: 'пункта меню',
        icon: 'menu',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'title',
                headerName: 'Наименование',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {
                field: 'code',
                headerName: 'Код',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'ord', headerName: 'Порядок', flex: 1, fieldType: 'text'},
        ],
        sortModel: {field: 'ord', sort: 'asc'}
    },
    {
        code: 'subscriber',
        title: 'Подписчики',
        title2: 'подписчика',
        icon: 'mail',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'email',
                headerName: 'Электронная почта',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле').email('Неверный формат адреса электронной почты'), ...email
            },
            {field: 'confirmed', headerName: 'Подтвержден', flex: 1, fieldType: 'boolean', ...bool},
        ]
    },
    {
        code: 'guestbook/captcha',
        title: 'Варианты каптчи',
        title2: 'варианта каптчи',
        icon: 'local_police',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'imagePath',
                headerName: 'Изображение',
                width: 100,
                fieldType: 'text',
                editability: 'auto', ...image
            },
            {
                field: 'imageId',
                headerName: 'Изображение',
                flex: 1,
                fieldType: 'file',
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png'],
                hide: true
            },
            {
                field: 'names',
                headerName: 'Варианты ответов',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
        ]
    },
    {
        code: 'gallery',
        title: 'Галереи',
        title2: 'галереи',
        icon: 'photo',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'imagePath',
                headerName: 'Изображение',
                width: 100,
                fieldType: 'text',
                editability: 'auto', ...image
            },
            {
                field: 'imageId',
                headerName: 'Изображение',
                flex: 1,
                fieldType: 'file',
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png'],
                hide: true,
                uploaderPath: '/api/admin/gallery/cover/upload',
                additionalParams: [{param: 'galleryId', objParam: 'id'}],
                editability: 'noCreate'
            },
            {
                field: 'title',
                headerName: 'Название',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'date', headerName: 'Дата', flex: 1, fieldType: 'date', ...dateTime},
        ],
        sortModel: {field: 'date', sort: 'desc'}
    },
    {
        code: 'food-category',
        title: 'Меню',
        title2: 'категории меню',
        icon: 'restaurant',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {field: 'imagePath', headerName: 'Иконка', width: 100, fieldType: 'text', editability: 'auto', ...image},
            {
                field: 'iconId',
                headerName: 'Иконка',
                flex: 1,
                fieldType: 'file',
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png', 'image/svg+xml'],
                hide: true
            },
            {
                field: 'title',
                headerName: 'Название',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
        ],
    },
    {
        code: 'food-item',
        title: 'Блюда',
        title2: 'блюда',
        icon: 'restaurant',
        fieldsMap: [
            {field: 'id', headerName: 'ID', width: 100, fieldType: 'text', editability: 'auto', hide: true},
            {field: 'categoryId', headerName: 'Категория', flex: 1, fieldType: 'text', editability: 'auto', hide: true},
            {
                field: 'imagePath',
                headerName: 'Изображение',
                width: 100,
                fieldType: 'text',
                editability: 'auto', ...image
            },
            {
                field: 'imageId',
                headerName: 'Изображение',
                flex: 1,
                fieldType: 'file',
                complementaryProps: ['imagePath'],
                accept: ['image/jpeg', 'image/png'],
                hide: true
            },
            {
                field: 'title',
                headerName: 'Название',
                flex: 1,
                fieldType: 'text',
                validation: Yup.string().required('Это обязательное поле')
            },
            {field: 'price', headerName: 'Цена', flex: 1, fieldType: 'text'},
            {field: 'compound', headerName: 'Состав', flex: 1, fieldType: 'textarea'},
            {field: 'weight', headerName: 'Граммовка', flex: 1, fieldType: 'text'},
        ],
        hidden: true
    }
]