import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import IconClose from '@material-ui/icons/Close'
import {useDispatch} from 'react-redux'
import {appSetMode, overpageShow} from '../../redux/actions/app'

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 64px)',
        position: 'absolute',
        zIndex: 100,
        top: 64,
        left: 0,
        width: '100%',
        background: '#fafafa',
        padding: 24,
        paddingBottom: 150
    },
    closeButton: {
        position: 'absolute',
        right: 24,
        top: 14
    }
}))

interface IOverpageProps {
    component?: any
    children?: any
}

export const OverPage = ({component, children}: IOverpageProps) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const handleCloseClick = () => {
        dispatch(overpageShow(false))
        dispatch(appSetMode('default'))
    }

    return (
        <Box className={classes.root}>
            <IconButton onClick={handleCloseClick} className={classes.closeButton}>
                <IconClose/>
            </IconButton>
            {component}
            {children}
        </Box>
    )
}

export default OverPage