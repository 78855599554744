import * as Yup from 'yup'

export const getValidationSchema = (fieldsMap) => {
    const fieldsToValidate = getFieldsValidationMap(fieldsMap)
    return Yup.object().shape(fieldsToValidate)
}

export const getFieldsValidationMap = (fieldsMap) => {
    let fieldsToValidate = {}
    fieldsMap.map((fieldData) => {
        Object.keys(fieldData).map(_ => {
            if (fieldData.fieldType !== 'auto' && fieldData.validation) {
                fieldsToValidate = {
                    ...fieldsToValidate,
                    [fieldData.field]: fieldData.validation
                }
            }
        })
    })
    return fieldsToValidate
}

export const getEmptyEntityFields = (fieldsMap, prefillParams = {}) => {
    let emptyData = {}
    fieldsMap.map((fieldData) => {
        Object.keys(fieldData).map(_ => {
            //if (fieldData.editability !== 'auto' && fieldData.editability !== 'readonly') {
                emptyData = {
                    ...emptyData,
                    ...prefillParams,
                    [fieldData.field]: ''
                }
            //}
        })
    })
    return emptyData
}

export const getAssignedObject = (fieldsMap, item) => {
    const emptyData = getEmptyEntityFields(fieldsMap)
    let result = emptyData
    if (item) {
        Object.keys(emptyData).map((key) => {
            result = {
                ...result,
                [key]: item[key]
            }
        })
    }
    return result
}