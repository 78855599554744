export const APP_OVERPAGE = 'APP/TOGGLE_OVERPAGE'
export const APP_SET_MODE = 'APP/SET_MODE'
export const APP_SET_CURRENT_ENTITY = 'APP/SET_CURRENT_ENTITY'
export const APP_SET_PREFILLED_PARAMS = 'APP/SET_PREFILLED_PARAMS'
export const APP_CREATE_ENTITY_ITEM = 'APP/CREATE_ENTITY_ITEM'
export const APP_SET_EDIT_ENTITY_ITEM = 'APP/SET_EDIT_ENTITY_ITEM'
export const APP_SET_SYSTEM_MESSAGE = 'APP/SET_SYSTEM_MESSAGES'
export const APP_SHOW_DIALOG = 'APP/SHOW_DIALOG'
export const APP_SHOW_COPY_DIALOG = 'APP/SHOW_COPY_DIALOG'
export const APP_SET_DIALOG_PARAMS = 'APP/SET_DIALOG_PARAMS'
export const APP_SET_DIALOG_ITEM_ID = 'APP/SET_DIALOG_ITEM_ID'
export const APP_SET_DIALOG_ENTITY = 'APP/SET_DIALOG_ENTITY'
export const APP_SET_DIALOG_MODE = 'APP/SET_DIALOG_MODE'
export const APP_SET_DIALOG_CURRENT_ENTITY_ITEM = 'APP/SET_DIALOG_CURRENT_ENTITY_ITEM'

export const SET_USER = 'USER/SET_USER'
export const UNSET_USER = 'USER/UNSET_USER'

export const SET_LIST_FIELDS = 'LIST/SET_FIELDS'
export const SET_LIST_DATA = 'LIST/SET_DATA'
export const SET_LIST_TOTAL_PAGES = 'LIST/SET_TOTAL_PAGES'
export const SET_LIST_CURRENT_PAGE = 'LIST/SET_CURRENT_PAGE'
export const SET_LIST_TOTAL_ELEMENTS = 'LIST/SET_TOTAL_ELEMENTS'
export const SET_LIST_SORT_MODEL = 'LIST/SET_SORT_MODEL'
export const LIST_LOADING = 'LIST/LOADING'
export const SET_LIST_SELECTION_MODEL = 'LIST/SET_SELECTION_MODEL'

export const SET_GALLERY_DATA = 'GALLERY/SET_DATA'

export const SET_FOOD_CATEGORY_DATA = 'FOOD_CATEGORY/SET_DATA'