import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles, withWidth
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {entities} from '../../data/entities'
import {setDialogParams, setPrefilledParams, showDialog} from '../../redux/actions/app'
import {IState} from '../../utils/models/IState'
import EntityForm from '../Forms/EntityForm'

const useStyles = makeStyles(() =>
    createStyles({
        root: {

        },
        content:{
            width: '100%'
        }
    }),
)

export const SimpleDialog = ({width}) => {
    const classes = useStyles()
    const {dialogIsOpen, dialogParams} = useSelector((state: IState) => state.app, shallowEqual)
    const [enableForm, setEnableForm] = useState(false)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setPrefilledParams({}))
        dispatch(showDialog(false))
    }

    useEffect(() => {
        (dialogParams.currentEntityItem || dialogParams.type === 'create') && setEnableForm(true)
    }, [dialogParams])

    const entityData = entities.find(item => {
        return item.code === dialogParams.currentEntity
    })

    return (
        <Dialog fullScreen={width === 'xs'} open={dialogIsOpen} className={classes.root}>
            {enableForm && <DialogTitle
                className={'title'}>{dialogParams.type !== 'create' ? 'Редактирование' : 'Добавление'} {entityData.title2}</DialogTitle>}
            <DialogContent className={classes.content}>
                {enableForm &&
                <EntityForm type={dialogParams.type} inModal />
                }
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between', padding: 30}}>
                <Button type={'submit'} onClick={handleClose} form={'modal-form'} color='primary' variant={'contained'} disableElevation>
                    {dialogParams.type === 'edit' ? 'Сохранить' : 'Добавить' }
                </Button>
                <Button onClick={handleClose} color='primary' variant={'contained'} disableElevation>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withWidth()(SimpleDialog)