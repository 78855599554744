import axios from 'axios'
import {Dispatch} from 'redux'
import {appLogger} from '../../helpers/appLogger'
import {TAppMode} from '../../utils/models/IAppState'
import {IDialogParams} from '../../utils/models/IDialog'
import store from '../store'
import qs from 'querystring'

import {
    APP_OVERPAGE,
    APP_SET_DIALOG_CURRENT_ENTITY_ITEM,
    APP_SET_DIALOG_ENTITY,
    APP_SET_DIALOG_ITEM_ID, APP_SET_DIALOG_MODE,
    APP_SET_DIALOG_PARAMS,
    APP_SET_EDIT_ENTITY_ITEM,
    APP_SET_MODE, APP_SET_PREFILLED_PARAMS,
    APP_SET_SYSTEM_MESSAGE, APP_SHOW_COPY_DIALOG,
    APP_SHOW_DIALOG,
    LIST_LOADING
} from '../types'
import {getListData} from './list'

export const overpageShow = (show = true) => {
    return {
        type: APP_OVERPAGE,
        payload: show
    }
}

export const appSetMode = (mode: TAppMode) => {
    return {
        type: APP_SET_MODE,
        payload: mode
    }
}

export const editEntity = (id: string) => {
    return async (dispatch: Dispatch) => {
        await dispatch(getEntityItemById(id) as any)
        dispatch(appSetMode('edit'))
        dispatch(overpageShow())
    }
}

export const addEntityItem = () => {
    return async (dispatch: Dispatch) => {
        dispatch({type: APP_SET_EDIT_ENTITY_ITEM, payload: null})
        dispatch(appSetMode('create'))
        dispatch(overpageShow())
    }
}

export const getEntityItemById = (id: string, modal: boolean = false) => {
    const appState = store.getState().app
    const entity = !modal ? appState.currentEntity : appState.dialogParams.currentEntity
    return async (dispatch: Dispatch) => {
        try {
            const entityItem = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/${entity}/${id}`)
                .then(res => res.data)
            dispatch({type: APP_SET_EDIT_ENTITY_ITEM, payload: entityItem})
            appLogger('getObj', entity)
        } catch (err) {
            appLogger('getObj', entity, err)
        }
    }
}

export const createEntityItem = (entityData, entity) => {
    const appState = store.getState().app
    return async (dispatch: Dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/${entity}`, entityData)
                .then(res => res.data)
            dispatch(setPrefilledParams({}))
            if(appState.editEntityItem && appState.dialogParams.type === 'create' || appState.dialogParams.type === 'edit'){
                dispatch(getEntityItemById(appState.editEntityItem.id) as any)
            }else {
                dispatch(overpageShow(false))
                dispatch(getListData(entity) as any)
            }
            appLogger('createObj', entity)
        } catch (err) {
            dispatch(setPrefilledParams({}))
            appLogger('createObj', entity, err)
        }
    }
}

export const updateEntityItem = (id: string, entityData, modal: boolean = false) => {
    const state = store.getState()
    const appState = state.app
    const listState = state.list
    const entity = appState.currentEntity
    const mEntity = modal && appState.dialogParams.currentEntity
    return async (dispatch: Dispatch) => {
        try {
            const entityItem = await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/${mEntity || entity}/${id}`, entityData)
                .then(res => res.data)
            dispatch({type: APP_SET_EDIT_ENTITY_ITEM, payload: entityItem})
            dispatch(getListData(entity, listState.currentPage, listState.sortModel) as any)
            dispatch({type: APP_SET_SYSTEM_MESSAGE, payload: [{type: 'success', message: 'Изменения сохранены'}]})
            appLogger('updateObj', entity)
        } catch (err) {
            appLogger('updateObj', entity, err)
        }
    }
}

export const deleteEntity = (id: string, refreshList: boolean = true) => {
    const state = store.getState()
    const appState = state.app
    const listState = state.list
    const entity = appState.currentEntity
    return async (dispatch: Dispatch) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/${entity}/?id=${id}`)
                .then(res => res.data)
            dispatch(overpageShow(false))
            dispatch({type: APP_SET_EDIT_ENTITY_ITEM, payload: null})
            refreshList && dispatch(getListData(entity, listState.currentPage, listState.sortModel) as any)
            appLogger('deleteObj', entity)
        } catch (err) {
            appLogger('deleteObj', entity, err)
        }
    }
}

export const uploadFiles = (path: string, file: any, additionalParams: any) => {
    const formData = new FormData()
    formData.append('file', file)
    return async (dispatch: Dispatch) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}${path}`, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {...additionalParams}
            }).then(res => res.data)
            //appLogger('deleteObj', entity)
        } catch (err) {
            //appLogger('deleteObj', entity, err)
        }
    }
}

export const massDeleteItems = (ids) => {
    const state = store.getState()
    const appState = state.app
    const listState = state.list
    const entity = appState.currentEntity
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_LOADING, payload: true})
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/${entity}/all`, {
                params: {ids},
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(res => res.data)
            dispatch(getListData(entity, listState.currentPage, listState.sortModel) as any)
        } catch (err) {
            dispatch({type: LIST_LOADING, payload: false})
            console.log(err)
        }
    }
}

export const showDialog = (show = false) => {
    return {
        type: APP_SHOW_DIALOG,
        payload: show
    }
}

export const showCopyDialog = (show = false) => {
    return {
        type: APP_SHOW_COPY_DIALOG,
        payload: show
    }
}

export const setDialogItemId = (id: string, entity: string, type: TAppMode = 'edit') => {
    return async (dispatch: Dispatch) => {
        dispatch({type: APP_SET_DIALOG_ENTITY, payload: entity})
        dispatch({type: APP_SET_DIALOG_ITEM_ID, payload: id})
        try {
            if(id && type !== 'create') {
                const entityItem = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/${entity}/${id}`)
                    .then(res => res.data)
                dispatch({type: APP_SET_DIALOG_CURRENT_ENTITY_ITEM, payload: entityItem})
            }else{
                dispatch({type: APP_SET_DIALOG_CURRENT_ENTITY_ITEM, payload: null})
            }
            dispatch({type: APP_SET_DIALOG_MODE, payload: type})
            dispatch(showDialog(true))
            appLogger('getObj', entity)
        } catch (err) {
            appLogger('getObj', entity, err)
        }
    }
}

export const setDialogParams = (params: IDialogParams) => {
    return (dispatch: Dispatch) => {
        dispatch({type: APP_SET_DIALOG_PARAMS, payload: params})
        dispatch(showDialog(true))
    }
}

export const setPrefilledParams = (params = {}) => {
    return {
        type: APP_SET_PREFILLED_PARAMS,
        payload: params
    }
}

