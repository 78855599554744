import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {uploadFiles} from '../../../redux/actions/app'
import {TFileTypes} from '../../../utils/models/TFileTypes'
import {IState} from '../../../utils/models/IState'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: 20

    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 3
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 500,
        border: '1px solid #ececec',
        padding: 15,
        borderRadius: 4,
        height: 180
    },
    image: {
        maxWidth: 200,
        maxHeight: '100%',
        marginRight: 20,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    dropZone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        textAlign: 'center',
        padding: 20,
        border: '2px dashed #ddd',
        borderRadius: 4,
        cursor: 'pointer',
        width: '100%',
        '&:focus, &:hover': {
            outline: 0,
            borderColor: theme.palette.primary.main
        }
    },
    deleteButton: {
        position: 'absolute',
        top: 3,
        left: 3,
        background: 'rgba(255,255,255,0.65)',
        padding: 5,
        color: 'rgba(255,38,104,1)',
        border: 'rgba(255,255,255,0.8)',
        '& .material-icons': {
            fontSize: 20
        },
        '&:hover': {
            background: 'rgba(255,255,255,0.8)',
        }
    },
    uploadedText: {
        color: theme.palette.success.main,
        fontSize: '0.75rem',
        margin: 0,
        padding: 0
    }
}))

interface IFileUploaderProps {
    name: string
    value: string
    label: string
    onChange?: (value: string, name: string) => (e: React.ChangeEvent<HTMLInputElement>) => void
    complementaryProps: string[]
    acceptTypes?: TFileTypes[]
    multiple?: boolean
    path: string
    additionalParams?: {}
    modal?: boolean
    method?: string
}

export const FileUploader: React.FC<IFileUploaderProps> = ({name, value, label, onChange, complementaryProps, acceptTypes, multiple = false, path, additionalParams, modal = false, method}) => {
    const classes = useStyles()
    const {editEntityItem, dialogParams, dialogIsOpen} = useSelector((state: IState) => state.app, shallowEqual)

    const localEditEntityItem = (modal && dialogIsOpen) ? dialogParams.currentEntityItem : editEntityItem

    const complementaryPropsValues = []
    if (localEditEntityItem) {
        complementaryProps.map((prop) => {
            complementaryPropsValues.push(localEditEntityItem[prop])
        })
    }
    const [filePath] = complementaryPropsValues
    const accept = acceptTypes.join(', ')

    const dispatch = useDispatch()
    const onDrop = useCallback(async acceptedFiles => {
        if (acceptedFiles.length > 0) {
            acceptedFiles.map(async (acceptedFile)=>{
                const file = await dispatch(uploadFiles(path, acceptedFile, additionalParams))
                file && onChange && onChange(file['id'], name)(null)
            })
        }
    }, [])

    const handleDelete = () => {
        onChange(null, name)(null)
    }

    const {
        fileRejections,
        acceptedFiles,
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: accept,
        multiple: multiple,
        onDrop
    })

    let fileAcceptedItems = acceptedFiles.map((file) => {
        return <span
            key={file['path']}
        >{file['path']}</span>
    })

    let fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={file['path']}>
            {file['path']}:
            {errors.map(e => (
                <span key={e.code}>{e.message} </span>
            ))}
        </li>
    ))

    return (
        <Box className={classes.root}>
            <Box className={classes.label}>{label}</Box>
            <section className={classes.container}>
                {filePath &&
                <Box className={classes.image}>
                    <IconButton className={classes.deleteButton} onClick={handleDelete}>
                        <Icon>highlight_off</Icon>
                    </IconButton>
                    <img
                        style={{minWidth: 32}}
                        src={(`${process.env.REACT_APP_API_URL}/files/${filePath}`)}
                        alt={'image'}
                    />
                </Box>
                }
                <Box {...getRootProps({className: classes.dropZone})}>
                    <input {...getInputProps()} />
                    <Box>
                        {fileAcceptedItems.length > 0 &&
                        <Box className={classes.uploadedText}>Загружено изображение {fileAcceptedItems}</Box>
                        }
                        <Typography variant={'body2'}>Кликните здесь или перетащите сюда файл для загрузки</Typography>
                        <Typography variant={'caption'}>Только {accept}</Typography>
                    </Box>
                </Box>
            </section>
            {fileRejections.length > 0 &&
            <Box>
                <h4>Отклоненные файлы</h4>
                <ul>{fileRejectionItems}</ul>
            </Box>
            }
        </Box>
    )
}
export default FileUploader