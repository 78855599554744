import {GridSortDirection} from '@material-ui/data-grid'
import axios from 'axios'
import {Dispatch} from 'redux'
import {appLogger} from '../../helpers/appLogger'
import {
    APP_SET_CURRENT_ENTITY,
    LIST_LOADING,
    SET_LIST_CURRENT_PAGE,
    SET_LIST_DATA,
    SET_LIST_FIELDS,
    SET_LIST_SELECTION_MODEL,
    SET_LIST_SORT_MODEL,
    SET_LIST_TOTAL_ELEMENTS,
    SET_LIST_TOTAL_PAGES
} from '../types'

export interface ISortModel {
    field: string
    sort: GridSortDirection
}

export const getListData = (entityRaw: string, page: number = 0, sort: ISortModel = {field: '', sort: 'asc'}) => {
    return async (dispatch: Dispatch) => {
        const entityA = entityRaw.split(':')
        const entity = entityA[0]
        const entityApiFilter = entityA[1]
        dispatch({type: LIST_LOADING, payload: true})
        dispatch({type: APP_SET_CURRENT_ENTITY, payload: entity})
        dispatch({type: SET_LIST_FIELDS, payload: []})
        dispatch({type: SET_LIST_DATA, payload: []})
        const srt = sort ? `&sort=${sort.field},${sort.sort}` : ''

        try {
            axios.defaults.withCredentials = true
            const listData = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/${entity}/list/${entityApiFilter ? entityApiFilter + '/' : '' }?size=15&page=${page}${srt}`)
                .then(res => res.data)
            const fields = Object.keys(listData.content[0]).map(field => {
                return {field: field, headerName: field, flex: 1}
            })
            dispatch({type: SET_LIST_SORT_MODEL, payload: sort})
            dispatch({type: SET_LIST_TOTAL_PAGES, payload: listData.totalPages})
            dispatch({type: SET_LIST_TOTAL_ELEMENTS, payload: listData.totalElements})
            dispatch({type: SET_LIST_FIELDS, payload: fields})
            dispatch({type: SET_LIST_DATA, payload: listData.content})
            dispatch({type: SET_LIST_CURRENT_PAGE, payload: page})
            dispatch({type: LIST_LOADING, payload: false})
            appLogger('list', entity)
        } catch (err) {
            dispatch({type: SET_LIST_FIELDS, payload: []})
            dispatch({type: SET_LIST_DATA, payload: []})
            dispatch({type: LIST_LOADING, payload: false})
            appLogger('list', entity, err)
        }
    }
}

export const setListSelection = (selectionModel) => {
    return {
        type: SET_LIST_SELECTION_MODEL,
        payload: selectionModel
    }
}