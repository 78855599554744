import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import {useEffect, useState} from 'react'
import * as React from 'react'
import {DataGrid} from '@material-ui/data-grid'
import Typography from '@material-ui/core/Typography'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {addEntityItem, editEntity, massDeleteItems} from '../../redux/actions/app'
import {getListData, setListSelection} from '../../redux/actions/list'
import {IState} from '../../utils/models/IState'
import {useParams} from 'react-router-dom'
import {entities} from '../../data/entities'
import {IUrlParams} from '../../utils/models/IUrlParams'

const useStyles = makeStyles(() => ({
    table: {
        cursor: 'default',
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-colCell:focus': {
            outline: 0,
        },
        '& .MuiDataGrid-colCell:focus': {
            userSelect: 'none'
        },
        '& .MuiDataGrid-row':{
            cursor: 'pointer'
        },
    },
    addBtn: {
        color: '#07a769',
        marginLeft: 10,
        padding: 5
    },
    removeBtn: {
        color: '#ff5252',
        padding: 5,
        marginLeft: 10
    }
}))

export const NoRows = () => {
    return (
        <Box style={{alignSelf: 'center', position: 'absolute', top: '60%'}}>
            Ничего не найдено
        </Box>
    )
}

export default function SimpleTable() {

    const classes = useStyles()
    const {entity: ent, nested} = useParams<IUrlParams>()
    const entity = nested ? ent + '/' + nested : ent || 'event'
    const entityData = entities.find(item => {
        return item.code === entity
    })

    const [deletion, setDeletion] = useState(false)

    const {
        data,
        totalElements,
        currentPage,
        sortModel,
        loading,
        selectionModel
    } = useSelector((state: IState) => state.list, shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getListData(entity, 0, entityData.sortModel))
    }, [entity])

    useEffect(() => {
        if (selectionModel.length > 0 && deletion) {
            dispatch(massDeleteItems(selectionModel))
            setDeletion(false)
        }
    }, [deletion])

    const columns = entityData.fieldsMap || data

    const handleRowClick = (e) => {
        dispatch(editEntity(e.row.id))
    }

    const handleAddClick = () => {
        dispatch(addEntityItem())
    }

    const handlePageChange = (e) => {
        dispatch(getListData(entity, e.page, sortModel))
    }

    const handleSortModelChange = (e) => {
        dispatch(getListData(entity, 0, e.sortModel[0]))
    }

    const handleSelectionChange = (e) => {
        dispatch(setListSelection(e.selectionModel))
    }

    const handleMassDelete = () => {
        setDeletion(true)
    }

    return (
        <>
            <Box pb={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography variant={'h5'}>
                    {entityData.title}
                </Typography>
                {!entityData.nonCreatable &&
                    <IconButton className={classes.addBtn} onClick={handleAddClick}>
                        <Icon>add</Icon>
                    </IconButton>
                }
            </Box>
            <div style={{width: '100%', background: '#fff'}}>

                <DataGrid
                    className={classes.table}
                    autoHeight
                    rows={data}
                    rowCount={totalElements}
                    columns={columns}
                    density={'compact'}
                    pagination
                    pageSize={15}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    paginationMode='server'
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    disableSelectionOnClick
                    checkboxSelection
                    loading={loading}
                    onRowClick={handleRowClick}
                    disableColumnFilter
                    onSelectionModelChange={handleSelectionChange}
                    selectionModel={selectionModel}
                    components={{
                        NoRowsOverlay: NoRows
                    }}
                    localeText={{
                        columnMenuLabel: 'Меню',
                        columnMenuShowColumns: 'Показать колонки',
                        columnMenuFilter: 'Фильтр',
                        columnMenuHideColumn: 'Спрятать',
                        columnMenuUnsort: 'Сбросить сортировку',
                        columnMenuSortAsc: 'Сортировать по возрастанию',
                        columnMenuSortDesc: 'Сортировать по убыванию',
                        footerRowSelected: (count) => {
                            let pluralForm = 'строк выбрано'
                            const lastDigit = count % 10
                            if (lastDigit > 1 && lastDigit < 5) {
                                pluralForm = 'строки выбраны'
                            } else if (lastDigit === 1) {
                                pluralForm = 'строка выбрана'
                            }
                            return (
                                <>
                                    {count} {pluralForm}
                                    <IconButton
                                        className={classes.removeBtn}
                                        onClick={handleMassDelete}
                                    >
                                        <Icon>delete_forever</Icon>
                                    </IconButton>
                                </>
                            )
                        },
                        footerTotalRows: 'Всего строк:'
                    }}
                />
            </div>
        </>
    )
}
