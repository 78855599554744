import {IAuthoredUser} from '../../utils/models/IAuthoredUser'
import {SET_USER, UNSET_USER} from '../types'

const localUser = JSON.parse(localStorage.getItem('admin.user'))

const initialState: IAuthoredUser = {
    profile: {
        username: localUser?.username,
        title: localUser?.title,
        roles: localUser?.roles
    }
}

export const authReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_USER: {
            return {...state, profile: {...state.profile, ...payload}}
        }
        case UNSET_USER: {
            return {...state, profile: null}
        }
        default:
            return state
    }
}