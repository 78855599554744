import axios from 'axios'
import Cookies from 'js-cookie'

export default class AuthService {

    constructor() {
    }

    signInRedirect = () => {
        window.location.href = '/login'
    }

    isAuthenticated = () => {
        const oidcStorage = JSON.parse(localStorage.getItem(`admin.user`))
        //const session = Cookies.get('SESSION')
        return (!!oidcStorage) //(typeof session !== 'undefined')
    }

    login = async (username, password) => {
        try {
            axios.defaults.withCredentials = true
            const user = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/auth`, {
                username: username,
                password: password,
            }).then(res => res.data)
            localStorage.setItem('admin.user', JSON.stringify(user))
            window.location.replace('/')
        } catch (err) {
            console.log(err)
        }
    }

    logout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/auth/logout`)
            localStorage.clear()
            Cookies.remove('SESSION')
            window.location.reload()
        } catch (err) {
            console.log(err)
        }
    }

}