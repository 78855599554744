import React from 'react'
import {Route} from 'react-router-dom'
import {AuthConsumer} from '../providers/authProvider'

export const PrivateRoute = ({component, ...rest}) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({isAuthenticated, signInRedirect}) => {
                if (!!Component && isAuthenticated()) {
                    return <Component {...props} />
                } else {
                    localStorage.removeItem('admin.user')
                    signInRedirect()
                    return <div>Loading...</div>
                }
            }}
        </AuthConsumer>
    )

    return <Route {...rest} component={renderFn(component)}/>
}